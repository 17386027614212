import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/LocationConstants";
import LocationService from "../../services/LocationService";

export function* FetchLocationSaga(payload) {
  try {
    const response = yield call(LocationService.FetchLocation, payload);
    yield put({ type: constant.FETCH_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FETCH_LOCATION_ERROR, error });
  }
}
// export function* FetchOrdersCountSaga(payload) {
//     try {
//         const response = yield call(LocationService.FetchOrdersCounts, payload);
//         yield put({ type: constant.FETCH_ORDERS_COUNT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.FETCH_ORDERS_COUNT_ERROR, error })
//     }
// }
export function* CreateLocationSaga(payload) {
  try {
    const response = yield call(
      LocationService.CreateLocation,
      payload?.formData
    );
    yield put({ type: constant.CREATE_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CREATE_LOCATION_ERROR, error });
  }
}
export function* UpdateLocationSaga(payload) {
  try {
    const response = yield call(
      LocationService.UpdateLocation,
      payload.formData
    );
    yield put({ type: constant.UPDATE_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPDATE_LOCATION_ERROR, error });
  }
}
export function* ViewLocationSaga(orderId) {
  try {
    const response = yield call(LocationService.ViewLocation, orderId);
    yield put({ type: constant.VIEW_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.VIEW_LOCATION_ERROR, error });
  }
}
export function* DeleteLocationSaga(orderId) {
  try {
    const response = yield call(LocationService.DeleteLocation, orderId);
    yield put({ type: constant.DELETE_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.DELETE_LOCATION_ERROR, error });
  }
}
// export function* MoveDispatchSaga(payload) {
//     try {
//         const response = yield call(LocationService.MoveDispatch, payload.formData);
//         yield put({ type: constant.MOVE_TO_DISPATCH_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.MOVE_TO_DISPATCH_ERROR, error })
//     }
// }
export function* CheckLocationSaga(payload) {
  try {
    const response = yield call(
      LocationService.CheckLocation,
      payload?.formData
    );
    yield put({ type: constant.LOCATION_CHECK_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.LOCATION_CHECK_ERROR, error });
  }
}
export function* UpdateLocationStatusSaga(payload) {
  try {
    const response = yield call(
      LocationService.UpdateLocationStatus,
      payload.formData
    );
    yield put({ type: constant.LOCATION_STATUS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.LOCATION_STATUS_ERROR, error });
  }
}
export default function* LocationSaga() {
  yield takeLatest(constant.FETCH_LOCATION_INIT, FetchLocationSaga);
  // yield takeLatest(constant.FETCH_ORDERS_COUNT_INIT, FetchOrdersCountSaga);
  yield takeLatest(constant.CREATE_LOCATION_INIT, CreateLocationSaga);
  yield takeLatest(constant.UPDATE_LOCATION_INIT, UpdateLocationSaga);
  yield takeLatest(constant.VIEW_LOCATION_INIT, ViewLocationSaga);
  yield takeLatest(constant.DELETE_LOCATION_INIT, DeleteLocationSaga);
  yield takeLatest(constant.LOCATION_CHECK_INIT, CheckLocationSaga);
  yield takeLatest(constant.LOCATION_STATUS_INIT, UpdateLocationStatusSaga);

  // yield takeLatest(constant.MOVE_TO_DISPATCH, MoveDispatchSaga);
}
