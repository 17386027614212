import * as constant from "../constants/RevenueConstants";

const initialState = {
  postInvoice: [],
  receivableList: [],
  orderpdflist: [],
  movereceivable_success: null,
  mailsend_success: null,
  loading: false,
  error: null,
  singlereceivable_list: [],
  revenuemailsend_success: null,
  revenueadd_success: false,
  revenuedelete_success: false,
  revenueedit_success: false,
};

const RevenueReducer = (state = initialState, { type, response }) => {
  switch (type) {
    case constant.FETCH_POSTINVOICE_INIT:
    case constant.MOVE_TO_RECEIVABLE_INIT:
    case constant.FETCH_RECEIVABLE_INIT:
    case constant.FETCH_CUSTOMER_PAYLIST:
    case constant.FETCH_ORDERPDF_INIT:
    case constant.FETCH_ORDERPDFEMAIL_INIT:
    case constant.FETCH_RECEIVABLESIGNLEVIEW_INIT:
    case constant.FETCH_REVENUEEMAIL_INIT:
    case constant.FETCH_RECEIVABLE_ADD_INIT:
    case constant.FETCH_RECEIVABLE_DELETE_INIT:
    case constant.FETCH_RECEIVABLE_EDIT_INIT:
      return {
        ...state,
        loading: true,
      };

    case constant.FETCH_POSTINVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        postInvoice: response.data,
      };

    case constant.FETCH_RECEIVABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        receivableList: response.data,
      };

    case constant.MOVE_TO_RECEIVABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        movereceivable_success: response,
      };

    case constant.FETCH_CUSTOMER_PAYLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        postInvoice: response.data,
      };

    case constant.FETCH_ORDERPDF_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        orderpdflist: response.data,
      };

    case constant.FETCH_ORDERPDFEMAIL_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        mailsend_success: response,
      };

    case constant.FETCH_RECEIVABLESIGNLEVIEW_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        singlereceivable_list: response.data,
      };

    case constant.FETCH_REVENUEEMAIL_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        revenuemailsend_success: response,
      };

    case constant.FETCH_RECEIVABLE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueadd_success: response,
      };

    case constant.FETCH_RECEIVABLE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        revenuedelete_success: response,
      };
    case constant.FETCH_RECEIVABLE_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueedit_success: response,
      };

    case constant.FETCH_POSTINVOICE_ERROR:
    case constant.MOVE_TO_RECEIVABLE_ERROR:
    case constant.FETCH_RECEIVABLE_ERROR:
    case constant.FETCH_CUSTOMER_PAYLIST_ERROR:
    case constant.FETCH_ORDERPDF_INIT_ERROR:
    case constant.FETCH_ORDERPDFEMAIL_ERROR:
    case constant.FETCH_RECEIVABLESIGNLEVIEW_ERROR:
    case constant.FETCH_REVENUEEMAIL_ERROR:
    case constant.FETCH_RECEIVABLE_ADD_ERROR:
    case constant.FETCH_RECEIVABLE_DELETE_ERROR:
    case constant.FETCH_RECEIVABLE_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: response,
      };
    default:
      return state;
  }
};

export default RevenueReducer;
