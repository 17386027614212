import fetch from "auth/FetchInterceptor";

const LocationService = {};

LocationService.FetchLocation = function (paginationParams) {
  return fetch({
    url: `/location?per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}`,
    method: "get",
  });
};
// LocationService.FetchLocationCounts = function () {
//     return fetch({
//         url: `/order-status-count`,
//         method: 'get'
//     })
// }
LocationService.CreateLocation = function (formData) {
  return fetch({
    url: "/location",
    method: "post",
    // headers: {
    //     'Content-Type': 'multipart/form-data'
    // },
    data: formData,
  });
};

LocationService.UpdateLocation = function (formData) {
  return fetch({
    url: "/location/" + formData.get("id"),
    method: "post",
    data: formData,
  });
};
LocationService.ViewLocation = function (data) {
  return fetch({
    url: "/location/" + data.orderId,
    method: "get",
  });
};
LocationService.DeleteLocation = function (orderId) {
  return fetch({
    url: "/location/" + orderId.orderId,
    method: "delete",
  });
};

LocationService.CheckLocation = function (formData) {
  return fetch({
    url: "/locations/check-address",
    method: "post",
    // headers: {
    //     'Content-Type': 'multipart/form-data'
    // },
    data: formData,
  });
};
// LocationService.UpdateLocationStatus = function (formData) {
//     return fetch({
//         url: '/location' + '/status-update/' + formData.get("id"),
//         method: 'post',
//         data: formData
//     })
// }

// LocationService.UpdateLocationStatus = function (data) {
// console.log(data);
//     return fetch({
//         url: '/location' + '/status-update/' + data?.id,
//         method: 'post',
//         data: data?.formData
//     })
// }

LocationService.UpdateLocationStatus = function (formData) {
  return fetch({
    url: "/location/status-update" + "/" + formData?.id,
    method: "post",
    data: formData,
  });
};
// LocationService.MoveDispatch = function (formData) {
//     return fetch({
//         url: '/move-to-dispatch',
//         method: 'post',
//         data: formData
//     })
// }

export default LocationService;
